export var MarketingPreferencesType;
(function (MarketingPreferencesType) {
    MarketingPreferencesType["AudienceMeasurement"] = "audienceMeasurement";
    MarketingPreferencesType["Operations"] = "operations";
    MarketingPreferencesType["TargetedAds"] = "targetedAds";
    MarketingPreferencesType["RecommendedInEmails"] = "recommendedInEmails";
})(MarketingPreferencesType || (MarketingPreferencesType = {}));
export var SensitiveTraitsPreferencesType;
(function (SensitiveTraitsPreferencesType) {
    SensitiveTraitsPreferencesType["SensitiveTraitsAccepted"] = "sensitiveTraitsAccepted";
})(SensitiveTraitsPreferencesType || (SensitiveTraitsPreferencesType = {}));
export var NotificationsPreferencesType;
(function (NotificationsPreferencesType) {
    NotificationsPreferencesType[NotificationsPreferencesType["None"] = 0] = "None";
    NotificationsPreferencesType[NotificationsPreferencesType["Push"] = 1] = "Push";
    NotificationsPreferencesType[NotificationsPreferencesType["Email"] = 2] = "Email";
    NotificationsPreferencesType[NotificationsPreferencesType["PushAndEmail"] = 3] = "PushAndEmail";
})(NotificationsPreferencesType || (NotificationsPreferencesType = {}));
export var UserBaseStatus;
(function (UserBaseStatus) {
    UserBaseStatus["Active"] = "ACTIVE";
    UserBaseStatus["Banned"] = "BANNED";
    UserBaseStatus["PendingBanned"] = "PENDING_BANNED";
    UserBaseStatus["Disabled"] = "DISABLED";
    UserBaseStatus["Deactivated"] = "DEACTIVATED";
    UserBaseStatus["PendingDeactivated"] = "PENDING_DEACTIVATED";
    UserBaseStatus["PendingDisabled"] = "PENDING_DISABLED";
    UserBaseStatus["Deleted"] = "DELETED";
    UserBaseStatus["PendingDeleted"] = "PENDING_DELETED";
    UserBaseStatus["SignUpValidation"] = "SIGN_UP_VALIDATION";
    UserBaseStatus["Unknown"] = "UNKNOWN";
})(UserBaseStatus || (UserBaseStatus = {}));
export var MyRelation;
(function (MyRelation) {
    MyRelation["Accepted"] = "accepted";
    MyRelation["Blocked"] = "blocked";
    MyRelation["Rejected"] = "rejected";
    MyRelation["Mutual"] = "mutual";
})(MyRelation || (MyRelation = {}));
export var VerificationStatusType;
(function (VerificationStatusType) {
    VerificationStatusType["Unverified"] = "unverified";
    VerificationStatusType["Pending"] = "pending";
    VerificationStatusType["Verified"] = "verified";
})(VerificationStatusType || (VerificationStatusType = {}));
export var UserProfileType;
(function (UserProfileType) {
    UserProfileType["Gift"] = "gift";
    UserProfileType["Crossing"] = "crossing";
    UserProfileType["NewReg"] = "new-reg";
    UserProfileType["Opportunity"] = "opportunity";
    UserProfileType["Sponsor"] = "sponsor";
})(UserProfileType || (UserProfileType = {}));
export const isGiftProfile = (profile) => profile.type === UserProfileType.Gift;
export const isCrossingProfile = (profile) => profile.type === UserProfileType.Crossing;
export const isNewRegProfile = (profile) => profile.type === UserProfileType.NewReg;
export const isOpportunityProfile = (profile) => profile.type === UserProfileType.Opportunity;
export const isSponsorProfile = (profile) => profile.type === UserProfileType.Sponsor;
export const USER_ID_ME = 'me';
export const UserProfileTypeName = 'UserProfile';
export const UserTypeName = 'User';
export const UsersSearchTypeName = 'UsersSearch';
export const UserUploadImageTypeName = 'UserUploadImage';
export const UserSaveTraitAnswerTypeName = 'UserSaveTraitAnswer';
export const UserMobileIdsTypeName = 'UserMobileIds';
export const UserMobileTokensTypeName = 'UserMobileTokens';
export const UserPhoneNumbersTypeName = 'UserPhoneNumbers';
export const UserSubscriptionTypeName = 'UserSubscription';
export const UserDevicesTypeName = 'UserDevices';
export const UserRecoveryInfoTypeName = 'UserRecoveryInfo';
export const UserRecoveryTokenTypeName = 'UserRecoveryToken';
export const UserAudioTypeName = 'UserAudio';
export const UserLikesReceivedTypeName = 'UserLikesReceived';
export const UserCreditsTypeName = 'UserCredits';
export const UserOrdersTypeName = 'UserOrders';
export const UserOrderTypeName = 'UserOrder';
export const SubscriptionTypeName = 'Subscription';
export const SubscriptionOrderTypeName = 'SubscriptionOrder';
export const UserNotificationTypeName = 'UserNotification';
export const UserArchiveTypeName = 'UserArchive';
export const UserArchiveDownloadLinkTypeName = 'UserArchiveDownloadLink';
export const RelationshipsBetweenUsersTypeName = 'RelationshipsBetweenUsers';
export const UsersExportTypeName = 'UsersExport';
export const UserPurchaseStatusTypeName = 'UserPurchaseStatus';
export const UserAclsTypeName = 'UserAcls';
export const UserSegmentTypeName = 'UserSegment';
export const UserPaymentIntentsTypeName = 'UserPaymentIntents';
export const UserPaymentIntentTypeName = 'UserPaymentIntent';
export const UserLatestSubscriptionTypeName = 'UserLatestSubscription';
export const GenderAliasesTypeName = 'GenderAliases';
