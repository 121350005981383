var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import loggerService from '@happn/logger';
import { USER_ID_ME, USER_QUERY, } from '../../apollo';
import { UPDATE_TEASER_ANSWER_MUTATION, DELETE_TEASER_ANSWER_MUTATION, } from './gql';
const useTeasers = (id = USER_ID_ME) => {
    const apolloClient = useApolloClient();
    const updateAnswer = useCallback((teaser, overrideId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield apolloClient.mutate({
                mutation: UPDATE_TEASER_ANSWER_MUTATION,
                variables: {
                    id: overrideId || id,
                    input: {
                        type: teaser.type,
                        answers: teaser.answers,
                    },
                },
                refetchQueries: [{ query: USER_QUERY, variables: { id } }],
            });
            return !!(data === null || data === void 0 ? void 0 : data.teasersUpdate.success);
        }
        catch (err) {
            loggerService.error(new Error(`Could not update user teaser, ${err}`));
            return false;
        }
    }), [apolloClient, id]);
    const deleteAnswer = useCallback((payload) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const { data } = yield apolloClient.mutate({
                mutation: DELETE_TEASER_ANSWER_MUTATION,
                variables: {
                    userId: payload.userId,
                    optionId: payload.optionIds,
                },
                refetchQueries: [{ query: USER_QUERY, variables: { id } }],
            });
            if (!((_a = data === null || data === void 0 ? void 0 : data.deleteAnswer) === null || _a === void 0 ? void 0 : _a.success)) {
                loggerService.error(new Error('Could not delete teaser answer'));
                return Promise.reject(false);
            }
            return Promise.resolve(true);
        }
        catch (err) {
            loggerService.error(new Error(`Could not delete teaser answer, ${err}`));
            return Promise.reject(err);
        }
    }), [apolloClient, id]);
    return { updateAnswer, deleteAnswer };
};
export default useTeasers;
