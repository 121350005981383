import { gql } from '@apollo/client';
import { TraitsConfigurationTypeName } from './types';
export const GET_TRAITS_CONFIGURATION_QUERY = gql `
  query GetTraitsConfigurationQuery {
    traitsConfiguration @rest(path: "api/v2/traits", type: ${TraitsConfigurationTypeName}) {
      id
      name
      type
      order
      sensitive
      matchingPref
      icon
      shortTitle
      mainTitle
      minResponse
      maxResponse
      categories {
        id
        icon
        categoryText
        translatedText
      }
      options {
        type
        floatRange {
          defaultValueFemale
          defaultValueMale
          minValue
          maxValue
          metric
          step
        }
        userInput {
          id
          categoryId
          defaultText
          translatedText
          translationId
        }
        singleChoice {
          id
          defaultText
          translatedText
        }
        multiChoices {
          id
          categoryId
          defaultText
          translatedText
        }
      }
    }
  }
`;
