import { getLocationCity } from '@happn/cities';
import env from '@happn/env';
import { ApiTypes } from '@happn/types';
import { getAudios } from './audios';
import { getBalance } from './balance';
import { getUserCrossing } from './crossing';
import { getGender } from './gender';
import { getGenderAliases } from './genderAliases';
import { getFirstIp } from './ip';
import { getLastAcceptedVersions } from './lastAcceptedVersions';
import { getLocalizationInformation } from './localizationInformation';
import { getOnlineStatus } from './onlineStatus';
import { getPendingLikers } from './pendingLikers';
import { getPictures, getPicture } from './pictures';
import { getPreferences } from './preferences';
import { getUserRelations } from './relations';
import { getSocialMedia } from './socialMedia';
import { getSubscription } from './subscription';
import { getTraits, getTraitsV2 } from './traits';
import { getUnreadInfo } from './unreadInfo';
import { getVerification } from './verification';
export const getUser = (gto) => {
    var _a, _b, _c;
    const isModerator = gto.is_moderator || false;
    const isAdmin = gto.is_admin || false;
    const isSponsor = gto.type === 'sponsor' || false;
    const maybeAge = gto.age || undefined;
    const age = maybeAge && !isModerator && !isSponsor ? maybeAge : undefined;
    const gender = getGender(gto);
    return Object.assign(Object.assign({ id: gto.id || '', firstName: gto.first_name || '', lastName: gto.last_name || '', age: env.common.isConsole ? maybeAge : age, audios: getAudios(gto.audios), about: gto.about || '', gender, genderAlias: getGenderAliases(gto === null || gto === void 0 ? void 0 : gto.gender_alias), job: gto.job || '', workplace: gto.workplace || '', school: gto.school || '', deviceInfo: gto.device_info || 0, isModerator,
        isAdmin,
        isSponsor, birthdate: gto.birth_date || '', lastActivityDate: gto.modification_date, firstIp: getFirstIp(gto.first_ip), country: getLocalizationInformation(gto.country), language: getLocalizationInformation(gto.language), lastPositionUpdate: gto.last_position_update, registerDate: gto.register_date, onlineStatus: getOnlineStatus(gto.modification_date), status: gto.status, preferences: getPreferences(gto), traits: getTraits(gto.traits), traitsV2: getTraitsV2(gto.traits_v2), socialMedia: getSocialMedia(gto), spotifyTracksId: gto.spotify_tracks || [], picture: getPicture(gto.picture), pictures: getPictures(gto), distance: gto.distance || null, verification: getVerification(gto), unreadInfo: getUnreadInfo(gto), isSub: Boolean(gto.is_premium), lastAcceptedVersions: getLastAcceptedVersions(gto), balance: getBalance(gto), email: gto.login || '', subscription: getSubscription(gto.subscription), locationCity: getLocationCity(gto.location_city), residenceCity: getLocationCity(gto.residence_city), pendingLikers: getPendingLikers(gto), sensitiveTraitsPreferences: {
            sensitiveTraitsAccepted: ((_a = gto.sensitive_traits_preferences) === null || _a === void 0 ? void 0 : _a.sensitive_traits_accepted) || false,
        }, hideGenderAlias: (_c = (_b = gto.mysterious_mode_preferences) === null || _b === void 0 ? void 0 : _b.hide_gender_alias) !== null && _c !== void 0 ? _c : true, subscriptionLevel: gto.subscription_level || ApiTypes.SubscriptionLevelEnum.Freemium }, getUserRelations(gto)), getUserCrossing(gto));
};
